import React, {FC, useEffect} from "react";

const Widget: FC = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://cdn.ma4b.io/ma4b-authentication.min.js";
        document.body.appendChild(script);

        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://cdn.ma4b.io/ma4b-authentication.min.css';
        document.head.appendChild(link);

        return () => {
            document.body.removeChild(script);
            document.head.removeChild(link);
        };
    }, []);

    return (
        <div className="flex-grow min-w-full flex flex-row items-center justify-center pt-8">
            <div id="ma4b-authentication" data-key="5a919282-8803-47b4-a82d-479fa20d5bfd"
                 data-layout="card"/>
        </div>
    )
}

export default Widget;