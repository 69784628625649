import React, {FC} from "react";
import {CodeBracketIcon} from "@heroicons/react/16/solid";
import {capitalize} from "../utils/utilFunctions";

type HeaderProps = {
    title: string;
}

const Header: FC<HeaderProps> = ({title}) => {
    const environment: string = process.env.NODE_ENV;
    const environmentTitle: string = `${title} - ${capitalize(environment)} Environment`

    return (
        <div className="min-w-full px-14 py-7 border-b border-zinc-200 dark:border-white bg-white dark:bg-zinc-900">
            <div
                className="flex flex-row items-center gap-2 w-fit py-1.5 pl-1.5 pr-4 border border-dashed border-zinc-200 rounded-3xl dark:border-white">
                <div className="p-1.5 border border-zinc-200 rounded-full dark:border-white">
                    <CodeBracketIcon className="h-4 w-4 dark:text-white"/>
                </div>
                <span className="h-fit font-semibold dark:text-white">
                    {environmentTitle}
                </span>
            </div>
        </div>
    )
}

export default Header;