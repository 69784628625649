import React, {FC} from "react";

type FooterProps = {
    description: string;
}

const Footer: FC<FooterProps> = ({description}) => {
    return (
        <span className="px-14 py-8 text-zinc-500 dark:text-zinc-950">{description}</span>
    )
}

export default Footer;