import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import HostedPage from './HostedPage';

const rootElement = document.getElementById('root') as HTMLElement;

rootElement.classList.add('h-screen', 'bg-zinc-50', 'dark:bg-zinc-800');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <HostedPage />
  </React.StrictMode>
);
