import React, {FC} from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Widget from "./components/Widget";

const HostedPage: FC = () => {
    return (
        <div className="h-full min-w-full flex flex-col items-center">
            <Header title='Player One Betting'/>

            <Widget/>

            <Footer description={'© Copyright 2024 NetX Betting Limited. All rights reserved.'}/>
        </div>
    );
}

export default HostedPage;
